.auth-holder{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.auth-form{
    width: 25%;
    /*max-width: 25%;*/
    /*min-width: 200px;*/
    display: grid;
    grid-template-columns: 0.5fr 1fr; /* Two equal columns */
}

.auth-cell{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.auth-row input{
    width: 100%;
}
