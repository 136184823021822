.main-menue{
    pointer-events: auto;
    /*background-color: white;*/
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 102;
    /*border-radius: 24px;*/
}

.main-menue-option-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    min-width: 200px;
    justify-self: end;

    -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);

}

.main-menue-option-container-item{
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menue-option-container-item:hover{
    background-color: aliceblue;
}


.main-menue-option-container-item label{
    display: flex;
    flex-direction: column;
}


.main-menue .expanded-content {
    display: none;
    overflow: hidden;
    /*border: 1px solid #ccc;*/
    padding: 0;
    /*margin-top: 10px;*/
    transition: height 1.0s ease-out; /* Add transition for height */
}

.main-menue .expanded {
    display: block;
    padding: 10px;
    transition: height 1.0s ease-in; /* Add transition for height */
}

.main-menue .hamburger-container{
    /*background-color: white;*/
    /*border-radius: 50%;*/
    width: 100%;
    display: flex;
    height: 50px;
    justify-content: flex-end;
}


.main-menue .hamburger{
    background-color: white;
    border-radius: 50%;
    /*width: 100%;*/
    /*display: flex;*/
    /*height: 50px;*/
    /*justify-content: flex-end;*/
    -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.55);
}

.main-menue-option-container-item .nav{
    text-decoration: none;
    color: black;
}

.main-menue-option-container-item{
    cursor: pointer;
}
