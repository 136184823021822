.map-container{
    height: 100vh;
    width: 100vw;
}

.area-label {
    background: transparent;
    border: none;
    font-size: medium;
    color: black;
    font-weight: bold;
}

.custom-icon{
    /*background-color: red;*/
    position: relative;
}
.marker-number{
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    padding-right: 7px;
    color: white;
}
.custom-icon img{
    height: 26px;
    width: 26px;
}
/*.leaflet-bottom{*/
/*    right: 0px;*/
/*}*/

/*.leaflet-draw-section{*/
/*    !*display: flex;*!*/
/*    justify-content: center;*/
/*    border: 0px;*/
/*}*/
/*.leaflet-draw-toolbar{*/
/*    display: flex;*/
/*}*/
/*.leaflet-draw{*/
/*    box-sizing: border-box;*/
/*}*/
