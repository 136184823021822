.fill-screen{
    width: 100vw;
    height: 100vh;
    pointer-events:none;
}

.work-area{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    /*background-color: #61dafb;*/
    z-index: 101;
    display: flex;
    flex-direction: row;

    /*display: inline-block;*/
}

.work-column{

    /*display: inline-block;*/
    /*width: 33.33%;*/
    /*box-sizing: border-box;*/
    /*height: 100%;*/
    flex: 1;
    z-index: 100;
}

.work-column:last-child{
    /*justify-self: end;*/
}
