.orders{
}


.order-list-holder, .order-info-holder{
    padding-right: 20px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.order-info-holder{
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 0px;
}

.order-list-content, .order-info-content{
    box-sizing: border-box;
    pointer-events: auto;
    height: 80%;
    width: 50%;
    background-color: white;
    /*height: 100%;*/
    /*overflow: scroll;*/
    border-radius: 10px;

    -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.25);
}

.order-info-content{
    width: 80%;
    /*height: auto;*/
    /*padding: 20px;*/
}

.order-info-content .edit-area-bounds-button, .new-order .add-button {
    cursor: pointer;
    width: 100%;
    height: 30px;
    font-size: medium;
    background-color: white;
    border: 0px;
    border-radius: 7px;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}

.order-info-content .edit-area-bounds-button:hover{
    opacity: 50%;
}

.order-list-holder .new-order{
    padding: 10px;
    /*align-self: flex-end;*/
}

.order-list-content, .order-info-content{
    display: flex;
    flex-direction: column;
}

.order-list{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
    overflow-y: auto;
    /*overflow-x: hidden;*/
}

.order-list .item{
    width: 100%;
    /*background-color: red;*/
    font-size: medium;
    height: 50px;
    line-height: 50px;
    border: solid rgba(0,0,0,0.05);
    border-width: 0.5px 0px;
    /*border-top: 10px black;*/
    cursor: pointer;

}

.order-list .item-2{
    /*width: 100%;*/
    /*background-color: red;*/
    font-size: medium;
    height: 50px;
    line-height: 50px;
    border: solid rgba(0,0,0,0.05);
    border-width: 0.5px 0px;
    /*border-top: 10px black;*/
    cursor: pointer;
    justify-content: space-between;
    display: flex;
    /*padding: 10px;*/
    padding-left: 10px;
    padding-right: 10px;
}

.order-list .item-2 .capacityAndLocationInfo{
    display: flex;
    flex-direction: row;
}

.order-list .item:hover, .order-list .item-2:hover{
    background-color: aliceblue;
}

.order-list .item-2 div:nth-child(2){
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 65%;
}


/*AREA INFO*/

.order-scroll-holder{
    display: flex;
    flex-grow: 1;
    border-top-right-radius: 7pt;
    border-top-left-radius: 7pt;
    overflow: hidden;
}

.order-info input[name="orderTitle"]{
    font-size: 1.55rem; /*h3-size*/
    font-weight: bold;
    width: 100%;
    border: 0px;
}

.order-info input[name="orderCapacity"]{
    font-size: 1.2rem; /*h3-size*/
    font-weight: normal;
    width: 100%;
    border: 0px;
}


.order-info{
    /*padding: 20px;*/
    /*display: flex;*/
    /*flex-grow: 1;*/
    /*flex-direction: column;*/
    /*text-align: center;*/

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    /*text-align: center;*/

    padding: 20px;
    /*overflow-x: hidden;*/
    overflow-y: auto;
}

.order-info-holder .new-order{
    padding: 10px;
    /*align-self: flex-end;*/
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
