.red-circle-icon-holder{
    display: flex;
    align-items: center;
}

.red-circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    border-radius: 50%; /* Create a circular shape */
    background-color: red; /* Set background color to red */
    color: white; /* Set text color to white */
    font-weight: bold; /* Make the exclamation mark bold */
}

.red-circle-icon span {
    font-size: 15px; /* Adjust font size as needed */
}
